import React, { useState } from "react"
import ButtonB from "./ButtonB"
import { navigate } from "gatsby"
import "../styles/ContactForm.scss"
import ReCAPTCHA from "react-google-recaptcha"
import { Link } from "gatsby"
// import "gatsby-plugin-recaptcha"
//import Recaptcha from "react-recaptcha"

// var callback = function () {
//   console.log('Done!!!!');
// };
const RECAPTCHA_KEY = process.env.GATSBY_APP_SITE_RECAPTCHA_KEY
  ? process.env.GATSBY_APP_SITE_RECAPTCHA_KEY
  : process.env.GATSBY_RECAPTCHA_KEY

const ContactForm = () => {
  // const [submitting, setSubmitting] = useState(false)
  // const [submitted, setSubmitted] = useState(false)
  // const [name, setName] = useState("")
  // const handleSubmit = event => {
  //   setSubmitting(true)

  //   setTimeout(() => {
  //     setSubmitting(false)
  //     setSubmitted(true)
  //   }, 2000)
  //   setTimeout(() => {
  //     navigate("/")
  //   }, 6000)
  // }

  const [align, setAlign] = useState(true)
  return (
    <div
      className="inner-container"
      style={{
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <style dangerouslySetInnerHTML={{ __html: "\n\n" }} />

      <form
        name="contact"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        className="form column"
        //onSubmit={handleSubmit}
        action="thank-you"
        data-netlify-recaptcha="true"
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />
        <div className="row">
          <div className="column">
            <label htmlFor="">Name *</label>
            <input
              name="name"
              type="text"
              tabIndex={1}
              required
              className="form-input"
            // value={name}
            // onChange={e => {
            //   setName(e.target.value)
            // }}
            />
          </div>
          <div className="column">
            <label htmlFor="">Phone Number</label>
            <input
              name="phone"
              type="tel"
              tabIndex={2}
              // required
              className="form-input"
            />
          </div>
        </div>
        <div className="row">
          <div className="column">
            <label htmlFor="">Email *</label>
            <input
              name="email"
              type="email"
              tabIndex={3}
              required
              className="form-input"
            />
          </div>
          <div className="column">
            <label htmlFor="">Type of Enquiry *</label>
            <select
              name="enquiry"
              id="enquiry"
              tabIndex={4}
              required
              className={align ? "select-align" : "select-left"}
              defaultValue={""}
            >
              <option
                value=""
                defaultValue
                disabled
                hidden
                className="select-right"
              >
                &#9660;
              </option>
              <option
                value="VMware to Nutanix Migration"
                onClick={() => setAlign(false)}
              >
                VMware to Nutanix Migration
              </option>
              <option value="FinOps" onClick={() => setAlign(false)}>
                FinOps
              </option>
              <option
                value="Zero Trust Security"
                onClick={() => setAlign(false)}
              >
                Zero Trust Security
              </option>
              <option
                value="Application Management"
                onClick={() => setAlign(false)}
              >
                Application Management
              </option>
              <option
                value="Service Orchestration"
                onClick={() => setAlign(false)}
              >
                Service Orchestration
              </option>

              <option
                value="Full Stack Engineering"
                onClick={() => setAlign(false)}
              >
                Full Stack Engineering
              </option>
              <option value="Infrastructure" onClick={() => setAlign(false)}>
                Infrastructure
              </option>
              <option
                value="Strategic Consultancy"
                onClick={() => setAlign(false)}
              >
                Strategic Consultancy
              </option>

              <option value="general" onClick={() => setAlign(false)}>
                General Enquiry
              </option>
            </select>
          </div>
        </div>
        <label htmlFor="">Additional Information</label>
        <textarea
          name="Message"
          tabIndex={5}
          // required
          className="form-text-area"
          defaultValue={""}
        />

        <div className="row">
          <label style={{ fontSize: "14px", color: "#626366" }}>
            <input
              type="checkbox"
              id="popi"
              name="popi"
              value="popi"
              required
              style={{
                padding: "0",
                margin: "0",
                verticalAlign: "bottom",
                position: "relative",
                top: "11px",
              }}
            />
            <span style={{ fontWeight: "300" }}>
              {" "}
              &nbsp; I agree and consent to the{" "}
              <Link to="/privacy" style={{ color: "#005b85" }}>
                <strong>Privacy Policy</strong>
              </Link>
              , specifically consenting to Metsi processing and disclosing my
              data and communicating with me according to that policy. I
              understand that I can view and adjust my communication preferences
              at any time.
            </span>
          </label>
        </div>

        <ReCAPTCHA sitekey={RECAPTCHA_KEY} />

        <button
          name="submit"
          type="submit"
          id="contact-submit"
          data-submit="...Sending"
          style={{
            background: "none",
            border: "none",
            marginBottom: "4rem",
          }}
        >
          <ButtonB>Submit</ButtonB>
        </button>
        {/*submitting && <h5>Submtting Form...</h5>}
        {submitted && (
          <h5>
            Thanks {name}, we have received your message and will be in touch
            shortly
          </h5>
        )*/}
      </form>
    </div>
  )
}

export default ContactForm
